/*  Portfolio Section Styles */ 

.text-center {
    text-align: center;
}
.portfolio .container {
    padding-bottom: 40px;
}
.portfolio .portfolio-heading {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 40px;
}
.portfolio .portfolio-heading h2 {
    color: var(--text-black-700);
    font-weight: 500;
}
.portfolio .portfolio-item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin-bottom: 30px;
}
.portfolio .portfolio-item-inner {
    border: 6px solid var(--bg-black-100);
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
}

.portfolio .portfolio-item .portfolio-item-inner .portfolio-img img {
    width: 100%;
    height: 300px;
    display: block;
}



@media (max-width:991px) {
    .portfolio .portfolio-item {
        flex: 0 0 100%;
        max-width: 50%;
    }
}

@media (max-width:767px){
    .col-6,
    .portfolio .portfolio-item {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.style-switcher {
    position: fixed;
    right: 0;
    top: 60px;
    padding: 15px;
    width: 200px;
    border: 1px solid var(--bg-black-50);
    background: var(--bg-black-100);
    z-index: 101;
    border-radius: 5px;
    transition: all 0.3s ease;
    transform: translateX(100%);
}

.style-switcher.open {
    transform: translateX(-25px);
}

.style-switcher .s-icon {
    position: absolute;
    height: 40px;
    width: 40px;
    text-align: center;
    font-size: 20px;
    background: var(--bg-black-100);
    color: var(--text-black-900);
    right: 100%;
    border: 1px solid var(--bg-black-50);
    margin-right: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 50%;
    
}

.style-switcher .s-icon i {
    line-height: 40px;
}
.style-switcher .style-switcher-toggler {
    top: 0;
}
.style-switcher .day-night {
    top: 55px;
}

.style-switcher h4 {
    margin: 0 0 10px;
    color: var(--text-black-700);
    font-size: 16px;
    text-transform: capitalize;
}

.style-switcher .colors {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.style-switcher .colors span {
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid red;
}

.style-switcher .color-1 {
    background: #ec1839;
}
.style-switcher .color-2 {
    background: #fa5b04;
}
.style-switcher .color-3 {
    background: #37b182;
}
.style-switcher .color-4 {
    background: #1854b4;
}
.style-switcher .color-5 {
    background: #f021b2;
}



/* Responsive styles for smaller screens (max-width: 768px) */
@media (max-width: 768px) {
    .style-switcher {
        margin: 1px
    }
    

}

/* For extra small screens (max-width: 480px) */
@media (max-width: 480px) {
    .style-switcher {
        margin-right: 10px
    }


}
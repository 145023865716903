/* Basic styling */
.error-container {
    text-align: center;
    padding: 100px 20px;
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .error-code {
    font-size: 10rem;
    margin: 0;
    color: #ff6b6b;
    animation: pop-in 0.8s ease-in-out;
  }
  
  .error-message {
    font-size: 1.5rem;
    margin-top: 10px;
    animation: fade-in 1.2s ease-in-out;
  }
  
  .error-tip {
    font-size: 1.2rem;
    margin-top: 20px;
  }
  
  .error-link {
    color: #007BFF;
    text-decoration: none;
    font-weight: bold;
    animation: bounce 1s infinite;
  }
  
  .error-link:hover {
    text-decoration: underline;
  }
  
  /* Bouncing box animation */
  .bouncing-box {
    font-size: 3rem;
    margin-top: 50px;
    animation: bounce-box 1.5s infinite;
  }
  
  /* Animations */
  @keyframes pop-in {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes bounce-box {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }
  
@import url('https://fonts.googleapis.com/css2?family=Clicker+Script&family=Poppins:wght@200;300;400;500;600;700&display=swap');
:root {
    --skin-color: #ec1839;
    --bg-black-900: #f2f2fc;
    --bg-black-100: #fdf9ff;
    --bg-black-50: #e8dfec;
    --text-black-900: black;
    --text-black-700: #504e70;
}

body.dark {
    --bg-black-900: #151515;
    --bg-black-100: #222222;
    --bg-black-50: #393939;
    --text-black-900: #ffffff;
    --text-black-700: #e9e9e9;
}
body {
    margin: 0;
    padding: 0;
    line-height: 1.5;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
}
* {
    margin: 0;
    padding: 0;
    outline: none;
    text-decoration: none;
    box-sizing: border-box;
}

::before, ::after {
    box-sizing: border-box;
}

ul {
    list-style-type: none;
}

/* Section Styles */
.section {
    background-color: var(--bg-black-900);
    min-height: 100vh;
    display: block;
    padding: 0 30px;
    opacity: 1;
}
.hidden {
    display: none !important;
}
.main-content {
    padding-left: 270px;
}
.padd-15 {
    padding-left: 15px;
    padding-right: 15px;
}
.container {
    max-width: 1100px;
    width: 100%;
    margin: auto;
}
.section .container {
    padding-top: 60px;
    padding-bottom: 70px;
}
.section-title {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 60px;
}
.section-title h2 {
    font-size: 40px;
    color: var(--text-black-900);
    font-weight: 700;
    position: relative;
}

.section-title h2::before {
    content: "";
    height: 4px;
    width: 50px;
    background-color: var(--skin-color);
    position: absolute;
    top: 100%;
    left: 0;
}
.section-title h2::after {
    content: "";
    height: 4px;
    width: 25px;
    background-color: var(--skin-color);
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 8px;
}


.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
}
.btn {
    font-size: 16px;
    font-weight: 500;
    padding: 12px 35px;
    color: white;
    border-radius: 40px;
    display: inline-block;
    white-space: nowrap;
    border: none;
    background: var(--skin-color);
    transition: all 0.3s ease;
}
.btn:hover {
   transform: scale(1.25); 
}

.shadow-dark {
    box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
}
/* Aside stlyes */
.aside {
    width: 270px;
    background-color: var(--bg-black-100);
    position: fixed;
    left: 0;
    top:0;
    padding: 30px;
    height: 100%;
    border-right: 1px solid var(--bg-black-50);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.aside .logo {
    position: absolute;
    top: 50px;
    font-size: 30px;
    text-transform: capitalize;
}
.aside .logo a {
    color: var(--text-black-900);
    font-weight: 700;
   /*  background: red; */
    padding: 15px 20px;
    font-size: 30px;
    letter-spacing: 5px;
    position: relative;
}

.aside .logo a span {
    font-family: "Clicker SCript", cursive;
    font-size: 40px;
}
.aside .logo a::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-bottom: 5px solid var(--skin-color);
    border-left: 5px solid var(--skin-color);
    bottom: 0;
    left: 0;
}
.aside .logo a::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-top: 5px solid var(--skin-color);
    border-right: 5px solid var(--skin-color);
    top: 0;
    right: 0;
}

.aside .nav-toggler {
    height: 40px;
    width: 45px;
    border: 1px solid var(--bg-black-50);
    cursor: pointer;
    position: fixed;
    left: 300px;
    top: 20px;
    border-radius: 5px;
    background: var(--bg-black-50);
    display: none;
    align-items: center;
    justify-content: center;
}
.aside .nav-toggler span {
    height: 2px;
    width: 18px;
    background-color: var(--skin-color);
    display: inline-block;
    position: relative;
}

.aside .nav-toggler span::after {
    content: "";
    height: 2px;
    width: 18px;
    background-color: var(--skin-color);
    position: absolute;
    top: 6px;
    left: 0;
}
.aside .nav-toggler span::before {
    content: "";
    height: 2px;
    width: 18px;
    background-color: var(--skin-color);
    position: absolute;
    top: -6px;
    left: 0;
}


.aside .nav {
    margin-top: 50px;
}

.aside .nav li {
    margin-bottom: 20px;
    display: block;
}

.aside .nav li a {
    font-size: 16px;
    font-weight: 600;
    display: block;
    border-bottom: 1px solid var(--bg-black-50);
    color: var(--text-black-900);
    padding: 5px 15px;
}

.aside .nav li a.active {
    color: var(--skin-color);
}

.aside .nav li a i {
    margin-right: 15px;
}


/* home styles */

.home {
    min-height: 100vh;
    display: flex;
    color: var(--text-black-900)
}

.home .home-info {
    flex: 0 0 60%;
    max-width: 60%;
}

h3.hello {
    font-size: 28px;
    margin: 15px 0;
}

h3.hello span {
    font-family: 'Clicker Script', cursive;
    font-size: 30px;
    font-weight: 700;
    color: var(--skin-color);
}
h3.my-profession {
    font-size: 30px;
    margin: 15px 0;
}

.typing {
    color: var(--skin-color);
}

.home-info p {
    margin-bottom: 70px;
    font-size: 20px;
    color: var(--text-black-700);
}
.home .home-img {
    flex: 0 0 40%;
    max-width: 40%;
    text-align: center;
    position: relative;
}
.home-img::after {
    content: '';
    position: absolute;
    height: 80px; 
    width: 80px;
    border-bottom: 10px solid var(--skin-color);
    border-right: 10px solid var(--skin-color);
    right: 20px;
    bottom: -40px;
}
.home-img::before {
    content: '';
    position: absolute;
    height: 80px; 
    width: 80px;
    border-top: 10px solid var(--skin-color);
    border-left: 10px solid var(--skin-color);
    left: -20px;
    top: -40px;
}
.home .home-img img {
    margin: auto;
    border-radius: 5px;
    height: 400px;
    
}










@media (max-width:991px) {   
    .home .home-info {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .home .home-img {
        display: none;
    }
}

@media (max-width:767px){
    .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
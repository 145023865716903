/* Services Styles */

.service .container {
    padding-bottom: 40px;
}


.service .service-item {
    margin-bottom: 30px;
    flex: 0 0 33.33%;
    max-width: 33.33%;
}

.service .service-item .service-item-inner {
    background-color: var(--bg-black-100);
    border: 1px solid var(--bg-black-50);
    border-radius: 10px;
    padding: 30px 10px;
    text-align: center;
    transition: all 0.3 ease;
}

.service .service-item .service-item-inner:hover {
    box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
}

.service .service-item .service-item-inner .icon {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: block;
    margin: 0 auto 30px;
    text-align: center;
    transition: all 0.3s ease;
}
.service .service-item .service-item-inner .icon .fas {
    font-size: 40px;
    line-height: 60px;
    color: var(--skin-color);
    transition: all 0.3s ease;
}

.service .service-item .service-item-inner:hover .icon {
    background: var(--skin-color);
}

.service .service-item .service-item-inner:hover .icon .fas {
    font-size: 25px;
    color: #fff
}

.service .service-item .service-item-inner h4 {
    font-size: 18px;
    margin-bottom: 15px;
    color: var(--text-black-900);
    font-weight: 700;
    text-transform: capitalize;
}

.service .service-item .service-item-inner p {
    font-size: 16px;
    color: var(--text-black-700);
    line-height: 25px;
}



/* Make sure the parent row is a flex container */
.service .row {
    display: flex;
    flex-wrap: wrap; /* Allows multiple lines */
    justify-content: space-between; /* Optional: Adjusts spacing between items */
  }
  
  /* Service item styling */
  .service .service-item {
    flex: 1 1 33.33%; /* Adjusts basis to take up one third of the row and flex equally */
    display: flex;
    flex-direction: column;
    margin-bottom: 30px; /* Adjust margin as needed */
  }
  
  .service .service-item-inner {
    flex: 1; /* Allows the inner container to expand */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distributes space between title and description */
    padding: 30px 10px;
    background-color: var(--bg-black-100);
    border: 1px solid var(--bg-black-50);
    border-radius: 10px;
    text-align: center;
    transition: all 0.3s ease;
  }
  
  .service .service-item-inner:hover {
    box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
  }


  .truncate {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* Adjust if needed */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  @media (min-width: 720px) {
    .truncate {
      -webkit-line-clamp: unset; /* Remove truncation on larger screens */
    }
  }
  
  


@media (max-width:991px) { 
    .service .service-item {
        flex: 0 0 100%;
        max-width: 50%;
    }
}

@media (max-width:767px){
    .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
/*  Contact Styles */
.contact-title {
    color: var(--skin-color);
    text-align: center;
    font-size: 25px;
    margin-bottom: 20px;
}

.contact-sub-title {
    color: black;
    text-align: center;
    font-size: 15px;
    margin-bottom: 60px;
}

.contact .contact-info-item {
    flex: 0 0 25%;
    max-width: 25%;
    text-align: center;
    margin-bottom: 60px;
}

.contact .contact-info-item .icon {
    display: inline-block;
}

.contact .contact-info-item .icon .fas {
    font-size: 25px;
    color: var(--skin-color);
}

.contact .contact-info-item h4{
    font-size: 18px;
    font-weight: 700;
    color: var(--text-black-700);
    text-transform: capitalize;
    margin: 15px 0 5px;
}
h4.contact-sub-title {
    color:var(--text-black-700);
}
.contact .contact-info-item p, .contact .contact-info-item a{
    font-size: 16px;
    line-height: 25px;
    color: var(--text-black-700);
    font-weight: 400;
}

.contact .contact-form {
    flex: 0 0 100%;
    max-width: 100%;
}
.contact .contact-form .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}
.contact .contact-form .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.contact .contact-form .form-item {
    margin-bottom: 30px;
}
.contact .contact-form .form-item .form-control {
    width: 100%;
    height: 50px;   
    border-radius: 25px;
    background: var(--bg-black-100);
    border: 1px solid var(--bg-black-50);
    padding: 10px 25px;
    font-size: 16px;
    color: var(--text-black-700);
    transition: all 0.3s ease;
}
.contact .contact-form .form-item .form-control:focus{
    box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
}
.contact .contact-form .form-item textarea.form-control {
    height: 140px;
}
.contact .contact-form .btn {
    height: 50px;
    padding: 0 50px;
}




@media (max-width:991px) {
    .contact .contact-info-item {
        flex: 0 0 100%;
        max-width: 50%;
    }
}

@media (max-width:767px){
    .contact .contact-form,
    .contact .contact-info-item {
        flex: 0 0 100%;
        max-width: 100%;
    }
}